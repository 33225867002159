import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { As, Box, Flex, FlexProps, Icon } from '@chakra-ui/react';
import { NextRouter, useRouter } from 'next/router';

import { iconHoverStyle } from './EditorLayout';

export type SideNavItem = {
  id: string;
  icon: IconType | ReactNode;
  label: string | null;
  onClick: () => void;
  isActive: (router: NextRouter) => boolean;
  fillColor?: string;
};

export type SideNavigationProps = FlexProps & {
  items: SideNavItem[];
};

export function SideNavigation({ items = [], ...rest }: SideNavigationProps) {
  const router = useRouter();
  return (
    <Flex
      flexDirection="column"
      height="100%"
      alignItems="center"
      w="250px"
      position="relative"
      data-testid="sidenav"
      flexShrink={0}
      {...rest}
    >
      {items.map((item) => {
        const isActive = item.isActive(router);
        return (
          <Flex
            px={2.5}
            py={2}
            w="100%"
            _hover={iconHoverStyle}
            opacity={isActive ? 1 : 1}
            onClick={item.onClick}
            justify="left"
            bg={isActive ? 'activeMenu' : 'transparent'}
            aria-label={item.label || 'no label'}
            key={item.label}
            fontSize="12px"
            data-testid={`sidenav-${item.id || item.label}`}
          >
            <Icon as={item.icon as As<any>} width="18px" height="18px" fill={item.fillColor} />
            <Box px={2}>{item.label}</Box>
          </Flex>
        );
      })}
    </Flex>
  );
}

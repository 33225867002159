import { memo } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  chakra,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Label } from 'components/Typography';
import config from 'config';
import { useAuth } from 'contexts/AuthContext';
import useOrganization from 'hooks/useOrganization';
import useStore from 'state';

import Logo from '../../../public/logo.svg';
import LogoCompact from '../../../public/logo-compact.svg';
import OrganizationIcon from '../../../public/organization.svg';
import Alerts from './Alerts';
import Organizations from './Organizations';

function Header() {
  const { logout, account, isAuthenticated } = useAuth();

  const setShowCreateOrgModal = useStore((state) => state.setShowCreateOrgModal);

  const { organization } = useOrganization();

  const hoverStyle = { bg: 'gray.50' };

  const router = useRouter();

  const onMarketplacePage = router.pathname.startsWith('/marketplace');

  return (
    <Flex
      bg={'white'}
      borderBottomStyle="solid"
      borderBottomColor="border1"
      borderBottomWidth={1}
      justifyContent="space-between"
      h="45px"
      minH="45px"
      align="center"
      px={2}
      data-testid="header"
      zIndex={1200}
    >
      <Flex align="center">
        <Link href={config.views.graphs.pathname} passHref>
          <chakra.a px={2} display={{ base: 'none', md: 'block' }}>
            <Logo style={{ height: config.logoHeight }} />
          </chakra.a>
        </Link>
        <Link href={config.views.graphs.pathname} passHref>
          <chakra.a px={2} display={{ base: 'block', md: 'none' }}>
            <LogoCompact style={{ height: config.logoHeight }} />
          </chakra.a>
        </Link>
        <Flex ml={{ base: 0, md: 4 }}>
          <Button
            variant={'ghost'}
            borderBottom={onMarketplacePage ? '0px' : '2px solid'}
            borderColor={onMarketplacePage ? 'text4' : 'action'}
            fontSize={'sm'}
            borderRadius={0}
            py={3.5}
            mt={'4px'}
            mx={1}
            textColor={onMarketplacePage ? 'text2' : 'action'}
            onClick={() => onMarketplacePage && router.push(config.defaultPathname)}
            _hover={{ borderColor: 'action', textColor: 'action', borderBottom: '2px solid' }}
          >
            Home
          </Button>
          <Button
            variant={'ghost'}
            borderBottom={onMarketplacePage ? '2px solid' : '0px'}
            borderColor={onMarketplacePage ? 'action' : 'text4'}
            fontSize={'sm'}
            borderRadius={0}
            py={3.5}
            mt={'4px'}
            mx={1}
            textColor={onMarketplacePage ? 'action' : 'text2'}
            onClick={() => !onMarketplacePage && router.push(config.views.marketplace.pathname)}
            _hover={{ borderColor: 'action', textColor: 'action', borderBottom: '2px solid' }}
          >
            Marketplace
          </Button>
        </Flex>
      </Flex>

      {isAuthenticated && (
        <Flex gap={3} justify="center" align="flex-start">
          <Alerts />
          <Flex display={{ base: 'block', md: 'none' }}>
            <Menu autoSelect={false}>
              <MenuButton
                as={IconButton}
                aria-label="Menu"
                icon={<HamburgerIcon />}
                variant="outline"
              />
              <MenuList zIndex={12} color="text2">
                <MenuGroup title="Navigate">
                  <MenuItem
                    textStyle="label"
                    onClick={() => router.push(config.views.graphs.pathname)}
                    // _hover={hoverStyle}
                    // _focus={hoverStyle}
                  >
                    {config.views.graphs.title}
                  </MenuItem>
                  <MenuItem
                    textStyle="label"
                    onClick={() => router.push(config.views.secrets.pathname)}
                    _hover={hoverStyle}
                    _focus={hoverStyle}
                  >
                    {config.views.secrets.title}
                  </MenuItem>
                  <MenuItem
                    textStyle="label"
                    onClick={() => router.push(config.views.storage.pathname)}
                    _hover={hoverStyle}
                    _focus={hoverStyle}
                  >
                    {config.views.storage.title}
                  </MenuItem>
                  <MenuItem
                    textStyle="label"
                    onClick={() => router.push(config.views.connections.pathname)}
                    _hover={hoverStyle}
                    _focus={hoverStyle}
                  >
                    {config.views.connections.title}
                  </MenuItem>
                  <MenuItem
                    textStyle="label"
                    onClick={() => router.push(config.views.members.pathname)}
                    _hover={hoverStyle}
                    _focus={hoverStyle}
                  >
                    {config.views.members.title}
                  </MenuItem>
                  <MenuItem
                    textStyle="label"
                    onClick={() => router.push(config.views.billing.pathname)}
                    _hover={hoverStyle}
                    _focus={hoverStyle}
                  >
                    {config.views.billing.title}
                  </MenuItem>
                  <MenuItem
                    textStyle="label"
                    onClick={() => router.push(config.views.slack_connect.pathname)}
                    _hover={hoverStyle}
                    _focus={hoverStyle}
                  >
                    {config.views.slack_connect.title}
                  </MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title="Your organization">
                  <Box maxH="200px" overflowY="auto" my="-0.5rem">
                    {account?.organizations && (
                      <Organizations organizations={Object.values(account?.organizations)} />
                    )}
                  </Box>

                  <MenuDivider />
                </MenuGroup>
                <MenuItem
                  textStyle="label"
                  onClick={() => setShowCreateOrgModal(true)}
                  _hover={hoverStyle}
                  _focus={hoverStyle}
                  data-testid="create-org-button"
                >
                  Create Organization
                </MenuItem>
                <MenuItem
                  textStyle="label"
                  onClick={() => logout()}
                  _hover={hoverStyle}
                  _focus={hoverStyle}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Flex display={{ base: 'none', md: 'block' }}>
            <Menu autoSelect={false}>
              <MenuButton
                data-testid={'menu-button'}
                as={Button}
                variant="ghost"
                colorScheme="gray"
              >
                <Flex alignItems="center">
                  <Icon as={OrganizationIcon} />
                  <Label mx={1}>{organization?.slug}</Label>
                  <FiChevronDown />
                </Flex>
              </MenuButton>
              <MenuList zIndex={12} fontSize="sm" color="text2">
                <MenuGroup>
                  <Flex
                    fontSize="11px"
                    fontWeight={400}
                    mx={3}
                    my={2}
                    justifyContent={'space-between'}
                  >
                    <Text mr={1}>{account?.email}</Text>
                    <Text ml={1}>{account?.username}</Text>
                  </Flex>

                  <MenuDivider />
                  <Box maxH="200px" overflowY="auto" my="-0.5rem">
                    {account?.organizations && (
                      <Organizations organizations={Object.values(account?.organizations)} />
                    )}
                  </Box>

                  <MenuDivider />
                </MenuGroup>
                <MenuItem
                  textStyle="label"
                  onClick={() => setShowCreateOrgModal(true)}
                  _hover={hoverStyle}
                  _focus={hoverStyle}
                  data-testid="create-org-button"
                >
                  Create Organization
                </MenuItem>
                <MenuItem
                  textStyle="label"
                  onClick={() => logout()}
                  _hover={hoverStyle}
                  _focus={hoverStyle}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      )}
      {!isAuthenticated && (
        <Flex ml={'auto'}>
          <Button
            variant={'outline'}
            borderRadius={'56px'}
            size={'md'}
            px={7}
            color={'grey'}
            borderColor={'border1'}
            border={'2px solid'}
            onClick={() => {
              router.push({
                pathname: '/auth/login',
                query: { returnTo: router.query.returnTo || router.asPath },
              });
            }}
          >
            Sign up
          </Button>
        </Flex>
      )}
    </Flex>
  );
}

export default memo(Header);

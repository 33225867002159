import { ReactNode, useMemo } from 'react';
import { Box, ChakraProps, Flex, VStack } from '@chakra-ui/react';
import { NextRouter, useRouter } from 'next/router';

import Header from 'components/Header';
import config from 'config';
import useHandleOrgCreationCallback from 'hooks/useHandleOrgCreationCallback';

import { SideNavigation, SideNavItem } from './SideNavigation';

export const iconHoverStyle: ChakraProps = {
  opacity: 0.8,
  cursor: 'pointer',
};

type EditorLayoutProps = ChakraProps & {
  showSidebar?: boolean;
  showHeader?: boolean;
  hideOverflow?: boolean;
  heroContent?: ReactNode;
  children: ReactNode;
};

const { views } = config;

// @TODO: where should we set the default organization?
function EditorLayout({
  showSidebar = true,
  showHeader = true,
  hideOverflow = false,
  heroContent,
  children,
}: EditorLayoutProps) {
  const router = useRouter();

  useHandleOrgCreationCallback();

  const navItems: SideNavItem[] = useMemo(
    () => [
      {
        id: 'graphs',
        icon: views.graphs.icon,
        label: views.graphs.title,
        onClick: () => router.push(views.graphs.pathname),
        isActive: ({ pathname }: NextRouter) => pathname === views.graphs.pathname,
      },
      // {
      //   id: 'marketplace',
      //   icon: views.marketplace.icon,
      //   label: views.marketplace.title,
      //   onClick: () => router.push(views.marketplace.pathname),
      //   isActive: ({ pathname }: NextRouter) => pathname === views.marketplace.pathname,
      // },
      {
        id: 'secrets',
        icon: views.secrets.icon,
        label: views.secrets.title,
        onClick: () => router.push(views.secrets.pathname),
        isActive: ({ pathname }: NextRouter) => {
          return pathname === views.secrets.pathname;
        },
      },
      {
        id: 'storage',
        icon: views.storage.icon,
        label: views.storage.title,
        onClick: () => router.push(views.storage.pathname),
        isActive: ({ pathname }: NextRouter) => {
          return pathname === views.storage.pathname;
        },
      },
      {
        id: 'connections',
        icon: views.connections.icon,
        label: views.connections.title,
        onClick: () => router.push(views.connections.pathname),
        isActive: ({ pathname }: NextRouter) => {
          return pathname === views.connections.pathname;
        },
      },
      {
        id: 'members',
        icon: views.members.icon,
        label: views.members.title,
        onClick: () => router.push(views.members.pathname),
        isActive: ({ pathname }: NextRouter) => {
          return pathname === views.members.pathname;
        },
      },
      {
        id: 'billing',
        icon: views.billing.icon,
        label: views.billing.title,
        onClick: () => router.push(views.billing.pathname),
        isActive: ({ pathname }: NextRouter) => {
          return pathname === views.billing.pathname;
        },
      },
      // Temporarily removing until we have more settings to actual show
      // {
      //   id: 'settings',
      //   icon: views.settings.icon,
      //   label: views.settings.title,
      //   onClick: () => router.push(views.settings.pathname),
      //   isActive: ({ pathname }: NextRouter) => {
      //     return pathname === views.settings.pathname;
      //   },
      // },
      {
        id: 'slack',
        icon: views.slack_connect.icon,
        label: views.slack_connect.title,
        onClick: () => router.push(views.slack_connect.pathname),
        isActive: ({ pathname }: NextRouter) => {
          return pathname === views.slack_connect.pathname;
        },
      },
      {
        id: 'discord',
        icon: views.discord.icon,
        label: views.discord.title,
        onClick: () => window.open('https://discord.gg/patterns', '_blank', 'noopener,noreferrer'),
        isActive: ({ pathname }: NextRouter) => false,
        fillColor: '#7289da',
      },
      {
        id: 'documentation',
        icon: views.documentation.icon,
        label: views.documentation.title,
        onClick: () => window.open(config.docsUrl + '/intro', '_blank', 'noopener,noreferrer'),
        isActive: ({ pathname }: NextRouter) => false,
      },
      // Temporarily removing until we can better support
      // {
      //   id: 'colormode',
      //   icon: views.colormode.icon,
      //   label: views.colormode.title,
      //   onClick: () => router.push(views.colormode.pathname),
      //   isActive: ({ pathname }: NextRouter) => {
      //     return pathname === views.colormode.pathname;
      //   },
      // },
    ],
    [router]
  );

  return (
    <Flex direction="column" height="100%">
      {showHeader && <Header />}
      <Flex grow={1} height={'100%'} overflowY={hideOverflow ? 'hidden' : 'inherit'}>
        {showSidebar && <SideNavigation items={navItems} display={{ base: 'none', md: 'flex' }} />}
        <Box
          flex="1"
          data-testid="content"
          minW="0px"
          minH="100%"
          borderLeftColor="border1"
          borderLeftWidth={1}
          borderLeftStyle="solid"
        >
          <VStack>
            {heroContent}
            {children}
          </VStack>
        </Box>
      </Flex>
    </Flex>
  );
}

export default EditorLayout;

import { BsCheck2 } from 'react-icons/bs';
import { MenuItem, Spacer } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import useOrganization, { useOrganizationUID } from 'hooks/useOrganization';
import { Organization } from 'types/api';

type OrganizationsProps = {
  organizations: Organization[];
};

function Organizations({ organizations }: OrganizationsProps) {
  const queryClient = useQueryClient();
  const organizationUID = useOrganizationUID();
  const { setOrganization } = useOrganization();

  const onClick = async (org: Organization) => {
    await queryClient.getQueryCache().clear();
    setOrganization(org.uid!);
  };

  return (
    <>
      {organizations.map((org) => (
        <MenuItem
          m={3}
          data-testid={'menu-item'}
          width="calc(100% - 24px)"
          borderRadius={4}
          color="text2"
          fontWeight={600}
          key={org.uid}
          bg={org.uid === organizationUID ? 'activeMenu' : 'transparent'}
          onClick={() => onClick(org)}
          fontSize="xs"
        >
          {org.slug}
          {org.uid === organizationUID && (
            <>
              <Spacer />
              <BsCheck2 />
            </>
          )}
        </MenuItem>
      ))}
    </>
  );
}

export default Organizations;

import { useToast } from '@chakra-ui/react';
import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import Router from 'next/router';

import { useGetNewOrganizationURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

type OrganizationResponse = {
  uid: string;
  slug: string;
  description: string;
};

function useGetNewOrganization(
  sessionID: string | null,
  options?: UseQueryOptions<OrganizationResponse>
) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const url = useGetNewOrganizationURL(sessionID);

  const query = useQuery<OrganizationResponse>(
    ['sessionID', sessionID],
    async () => {
      const result = await Axios.get<OrganizationResponse>(url);
      await queryClient.invalidateQueries(['account']);
      // push the new org_uid as a query param so we can load it after we reload the account
      Router.push({
        pathname: `/home`,
        query: { new_org: result?.data?.uid },
      });

      setTimeout(() => {
        // add 1-second delay so toast isn't closed on page navigation
        toast({
          status: 'success',
          title: 'Organization Created',
          description: `You have successfully created organization: ${result.data.slug} `,
          duration: 3000,
          isClosable: true,
        });
      }, 1000);

      return result?.data;
    },
    {
      ...options,
      enabled: !!sessionID,
    }
  );

  return query;
}

export default useGetNewOrganization;

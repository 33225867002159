import { useRef, useState } from 'react';
import { FaBell } from 'react-icons/fa';
import {
  Box,
  Button,
  ButtonGroup,
  Circle,
  Link,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Tooltip,
} from '@chakra-ui/react';

import useAlerts from 'hooks/api/useAlerts';

type AlertsProps = {
  darkMode?: boolean;
};

function Alerts({ darkMode }: AlertsProps) {
  const { data: alerts } = useAlerts();
  const [index, setIndex] = useState(0);
  const initialFocusRef = useRef<HTMLButtonElement>(null);

  if (!alerts || alerts.alerts.length <= 0) {
    return <></>;
  }

  const currentAlert = alerts.alerts[0];

  return (
    <Popover placement="bottom-start" closeOnBlur={true} initialFocusRef={initialFocusRef}>
      <PopoverTrigger>
        <Button variant="ghost" size="sm" colorScheme={darkMode ? 'white' : 'gray'}>
          <>
            <FaBell color={darkMode ? 'white' : 'gray.500'} />
            <Circle
              as={'span'}
              color={'white'}
              position={'absolute'}
              top={'0px'}
              right={'3px'}
              fontSize={'0.8rem'}
              bgColor={'red'}
              zIndex={9999}
              p={'2px'}
            >
              {alerts?.alerts.length || 0}
            </Circle>
          </>
        </Button>
      </PopoverTrigger>
      <PopoverContent bg="white">
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          {currentAlert.title}
        </PopoverHeader>
        <PopoverCloseButton />
        <PopoverBody>
          {currentAlert.description}
          <Box pt={2}>
            <Tooltip label={currentAlert.fix_url} placement="top-start">
              <Link href={currentAlert.fix_url}>Fix it here</Link>
            </Tooltip>
          </Box>
        </PopoverBody>
        <PopoverFooter
          border="0"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        >
          <Box fontSize="sm">
            Alert {index + 1} of {alerts.alerts.length}
          </Box>
          <ButtonGroup size="sm">
            <Button
              colorScheme="blue"
              variant="outline"
              onClick={() => setIndex(index - 1)}
              disabled={index <= 0}
            >
              Previous
            </Button>
            <Button
              colorScheme="blue"
              variant="outline"
              ref={initialFocusRef}
              onClick={() => setIndex(index + 1)}
              disabled={index >= alerts.alerts.length - 1}
            >
              Next
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

export default Alerts;

import { useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';

import { useOrganizationUID } from 'hooks/useOrganization';
import { Alerts } from 'types/api';
import { useAlertsURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

function useAlerts(options?: UseQueryOptions<Alerts>) {
  const orgUID = useOrganizationUID();
  const url = useAlertsURL(orgUID);
  const queryClient = useQueryClient();

  return useQuery<Alerts>(
    ['alerts', orgUID],
    async () => {
      const res = await Axios.get<Alerts>(url);
      setTimeout(() => {
        queryClient.invalidateQueries(['alerts', orgUID]);
      }, 5 * 60 * 1000);
      return res.data;
    },
    {
      enabled: !!orgUID,
      ...options,
    }
  );
}

export default useAlerts;
